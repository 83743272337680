.deposit-method-wrapper {
    position: relative;
    box-shadow: hsla(var(--color-primary-hsl), 0) 0 25px 20px -20px;
    border-radius: $border-radius-card;
    transition: all 0.25s ease;

    &.hidden-ribbon {
        .ant-ribbon-corner {
            border: 0;
        }
    }

    .bg-secondary {
        background: $gradient-dark;
        border: 1px solid rgba($color-white, 0.05);

        .deposit-method-name {
            color: $color-white;

            small {
                color: rgba($color-white, 0.5);
            }
        }

        .deposit-method-icon-right {
            color: $color-white;
        }
    }

    &:hover {
        box-shadow: hsla(var(--color-primary-hsl), 1) 0 25px 20px -20px;
        transform: scale(1.015);

        .bg-secondary {
            box-shadow: rgba($color-black, 0.5) 0 25px 20px -20px;
        }

        .deposit-method-icon {
            transform: scale(1.15);
        }

        .deposit-method-name {
            transform: translateX(12px);
        }
    }
}

.deposit-method {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    padding: 0 16px 0 96px;
    height: 70px;
    color: var(--color-text-primary-bg);
    background: $gradient-primary;
    border-radius: $border-radius-card;
    box-shadow: inset 0 0 0 1px rgba($color-white, 0.2);
    transition: all 0.25s ease;
    cursor: pointer;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        bottom: -50px;
        left: -30px;
        width: 96px;
        height: 96px;
        background: rgba(0, 0, 0, 0.25);
        filter: blur(20px);
    }

    .deposit-method-icon {
        position: absolute;
        bottom: -12px;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 72px;
        height: 72px;
        margin-right: 16px;
        transition: all 0.25s ease;

        img {
            // width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .deposit-method-name {
        font-size: 28px;
        line-height: 0.8;
        text-shadow: 1px 2px 4px var(--color-text-shadow-primary-bg);
        transition: all 0.25s ease;

        @media (min-width: $screen-lg) {
            font-size: 32px;
        }

        small {
            color: var(--color-text-primary-bg);
            opacity: 0.75;
            font-size: 20px !important;

            @media (min-width: $screen-lg) {
                font-size: 24px !important;
            }
        }
    }

    .deposit-method-icon-right {
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
        font-size: 22px;
        text-shadow: 1px 2px 2px var(--color-text-shadow-primary-bg);
    }
}

.truewallet-qr-deposit {
    margin-top: 16px;
    text-align: center;

    img {
        width: 150px;
        border: 1px solid rgba($color-white, 0.2);
        border-radius: 0.25rem;
    }

    h5 {
        margin: 0.5rem 0 0 0;
        font-size: 1.2rem;
        color: $color-primary;
    }
}

.promotion-slider-container {
    overflow-x: auto;
    display: flex;
    margin: 0 -12px 16px;
    padding: 0 12px;

    @media (min-width: $screen-lg) {
        margin: 0 0 16px;
        padding: 0;
    }

    @media (min-width: $screen-xl) {
        &.center {
            justify-content: center;
        }
    }

    .promotion-slider-item {
        position: relative;
        font-size: 18px;
        color: $color-white;
        min-width: 110px;
        width: 110px;
        background: $gradient-dark;
        padding: 8px;
        box-sizing: border-box;
        text-align: center;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-shadow: 0 2px 4px rgba($color-black, 0.25);
        box-shadow: inset 0 0 0 1px rgba($color-white, 0.1);
        cursor: pointer;

        &:not(:last-child) {
            margin-right: 8px;
        }

        .promotion-slider-item-title {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
        }

        .promotion-slider-item-checked {
            position: absolute;
            top: 0;
            right: -2px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: $color-success;
            box-shadow: 0 2px 8px rgba($color-black, 0.25);
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .promotion-slider-item-label {
            position: absolute;
            top: 10px;
            left: -4px;
            padding: 0 4px;
            font-size: 16px;
            background: #f00000;
            border-radius: 0 4px 4px 0;

            .promotion-slider-item-label-conner {
                position: absolute;
                top: 100%;
                left: 0px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 4px 4px 0px;
                border-color: transparent transparent #b00505 transparent;
                transform: rotate(180deg);
                filter: brightness(75%);
            }
        }

        img {
            width: 20px;
            margin-bottom: 6px;
        }

        &.active {
            background: $gradient-secondary;
            box-shadow: inset 0 0 0 1px rgba($color-white, 0.2);

            .promotion-slider-item-title {
                color: var(--color-text-secondary-bg);
                text-shadow: 1px 2px 4px var(--color-text-shadow-secondary-bg);
            }
        }
    }
}